import React from "react";
import { Route, Routes } from "react-router-dom";
import CookiesPolicyView from "./cookies-policy-view";

const CookiesPolicyRoutes = React.memo(() => {
    return (
        <Routes>
            <Route path="/cookies-policy" element={<CookiesPolicyView />} />
        </Routes>
    );
});

export default CookiesPolicyRoutes;
