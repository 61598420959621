import * as DS from '../ds/style'
import { useLayoutContext } from "../shared/layout/layout-provider";
import { useEffect } from 'react'

function CookiesPolicyView(): JSX.Element {
    const { setBackgroundColor, setTextColor, setLightLogo } = useLayoutContext();

    useEffect(() => {
        setBackgroundColor(DS.colors.backgroundBase)
        setTextColor(DS.colors.contentHigh)
        setLightLogo(false)
    }, []);

    return (
        <DS.Container direction='column' center marginTop={DS.spacing.x10} marginBottom={DS.spacing.x10} width='70%'>
            <DS.Text>
                Cookies Policy
                <br /><br />
                Last updated: 01/03/2023
                <br /><br />
                Wifi Pass ("us", "we", or "our") uses cookies on www.wifi-pass.app (the "Service"). By using the Service, you consent to the use of cookies.
                <br /><br />
                Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.
                <br /><br />
                What are cookies?
                <br /><br />
                Cookies are small pieces of text sent to your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you.
                <br /><br />
                Cookies can be "persistent" or "session" cookies. Persistent cookies remain on your personal computer or mobile device when you go offline, while session cookies are deleted as soon as you close your web browser.
                <br /><br />
                How Wifi Pass uses cookies
                <br /><br />
                When you use and access the Service, we may place a number of cookies files in your web browser.
                <br /><br />
                We use cookies for the following purposes:
                <br /><br />
                To enable certain functions of the Service
                To provide analytics
                To store your preferences
                To enable advertisements delivery, including behavioral advertising
                We use both session and persistent cookies on the Service and we use different types of cookies to run the Service:
                <br /><br />
                Essential cookies. We may use essential cookies to authenticate users and prevent fraudulent use of user accounts.
                Analytical/performance cookies. We may use analytical/performance cookies to help us to improve the performance of our website to provide you with a better user experience. They allow us to recognize and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.
                Functionality cookies. We may use functionality cookies to recognize you when you return to our website. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
                Targeting cookies. We may use targeting cookies to record your visit to our website, the pages you have visited and the links you have followed. We may use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.
                Third-party cookies
                <br /><br />
                In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.
                <br /><br />
                What are your choices regarding cookies?
                <br /><br />
                If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser. Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.
                <br /><br />
                Where can you find more information about cookies?
                <br /><br />
                You can learn more about cookies and the following third-party websites:
                <br /><br />
                AllAboutCookies: <DS.Link href='http://www.allaboutcookies.org/'>http://www.allaboutcookies.org/</DS.Link>
                Network Advertising Initiative: <DS.Link href='http://www.networkadvertising.org/'>http://www.networkadvertising.org/</DS.Link>
            </DS.Text>
        </DS.Container>
    )
}

export default CookiesPolicyView;