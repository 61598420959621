import React from 'react';
import HomeRoutes from '../app/home';
import PrivacyPolicyRoutes from '../app/privacy-policy';
import TermsOfUseRoutes from '../app/terms-of-use';
import CookiesPolicyRoutes from '../app/cookies-policy';

const Navigation = React.memo(() => {
    return (
        <>
            <HomeRoutes />
            <PrivacyPolicyRoutes />
            <TermsOfUseRoutes />
            <CookiesPolicyRoutes />
        </>
    );
});

export default Navigation;