import styled, { css } from "styled-components";
import designSystem from "./theme"
import { em } from '@mantine/core';
import { Link as ReactLink } from "react-router-dom";

export const colors = designSystem.colors
export const fontSizes = designSystem.fontSizes
export const spacing = designSystem.spacing

interface ViewProps {
    align?: any;
    fullWidth?: any;
    height?: string;
    width?: string;
    maxWidth?: string;
    gap?: string;
    margin?: string;
    marginTop?: string;
    marginBottom?: string;
    padding?: string;
    top?: string;
    right?: string;
    left?: string;
    position?: string;
    backgroundColor?: string;
    direction?: string;
    justify?: string;
    alignItems?: string;
    alignSelf?: string;
    center?: any;
    centerContent?: any;
    flex?: string;
    flexWrap?: string;
    flexBasic?: string;
    responsive?: {
        mobile?: {
            height?: string;
            width?: string;
            maxWidth?: string;
            direction?: string;
            gap?: string;
            alignSelf?: string;
            alignItems?: string;
            justify?: string;
            marginTop?: string;
            marginBottom?: string;
            display?: string;
            flexWrap?: string;
            flexBasic?: string;
        }
        tablet?: {
            height?: string;
            width?: string;
            maxWidth?: string;
            direction?: string;
            alignSelf?: string;
            alignItems?: string;
            justify?: string;
            gap?: string;
            marginTop?: string;
            marginBottom?: string;
            display?: string;
            flexWrap?: string;
            flexBasic?: string;
        }
    }
}

interface TextProps {
    bold?: any;
    align?: any;
    color?: string;
    width?: string;
    marginTop?: string;
    marginBottom?: string;
    size?: string;
    responsive?: {
        mobile?: {
            display?: string;
            size?: string;
            width?: string;
            marginTop?: string;
            marginBottom?: string;
            align?: string;
        }
        tablet?: {
            display?: string;
            size?: string;
            width?: string;
            marginTop?: string;
            marginBottom?: string;
            align?: string;
        }
    }
}
export const Text = styled.span<TextProps>`
    font-size: ${props => props.size || designSystem.fontSizes.lowM};
    color: ${props => props.color || designSystem.colors.primaryHigh};
    font-family: ${props => props.bold ? designSystem.fontFamily.bold : designSystem.fontFamily.medium};
    text-align: ${props => props.align || 'initial'};
    color: ${props => props.color || designSystem.colors.contentHigh};
    width: ${props => props.width || '100%'};
    margin-top: ${props => props.marginTop || ''};
    margin-bottom: ${props => props.marginBottom || ''};
    
    @media (max-width: ${em('425')}) { 
        ${(props) =>
        props.responsive?.mobile &&
        css`
            display: ${p => props.responsive?.mobile?.display || ''};
            width: ${p => props.responsive?.mobile?.width || ''};
            font-size: ${p => props.responsive?.mobile?.size || ''};
            margin-top: ${p => props.responsive?.mobile?.marginTop || ''};
            margin-bottom: ${p => props.responsive?.mobile?.marginBottom || ''};
            text-align: ${p => props.responsive?.mobile?.align || ''};
        `}
    }

    @media (max-width: ${em('768')}) { 
        ${(props) =>
        props.responsive?.tablet &&
        css`
            display: ${p => props.responsive?.tablet?.display || ''};
            width: ${p => props.responsive?.tablet?.width || ''};
            font-size: ${p => props.responsive?.tablet?.size || ''};
            margin-top: ${p => props.responsive?.tablet?.marginTop || ''};
            margin-bottom: ${p => props.responsive?.tablet?.marginBottom || ''};
            text-align: ${p => props.responsive?.tablet?.align || ''};
        `}
    }
`
interface ColorProps {
    color?: string;
}
export const Color = styled(Text) <ColorProps>`
    color: ${props => props.color || designSystem.colors.contentHigh};
    font-family: inherit;
    font-size: inherit;
`

export const Container = styled.div<ViewProps>`
    ${(props) =>
        props.center &&
        css`
        margin: auto;
    `}
    display: flex;
    height: ${props => props.height || ''};
    width: ${props => props.width || '100%'};
    max-width: ${props => props.maxWidth || ''};
    padding: ${props => props.padding || '0px'};
    margin-top: ${props => props.marginTop || ''};
    margin-bottom: ${props => props.marginBottom || ''};
    margin: ${props => props.margin || ''};
    top: ${props => props.top || ''};
    gap: ${props => props.gap || '0px'};
    position: ${props => props.position || ''};
    background-color: ${props => props.backgroundColor || ''};
    justify-content: ${props => props.justify || ''};
    align-items: ${props => props.alignItems || ''};
    flex-direction: ${props => props.direction || 'column'};
    align-self: ${props => props.alignSelf || ''};
    flex-wrap: ${props => props.flexWrap || ''};
    flex-basis: ${props => props.flexBasic || ''};
    max-width: 90%;
    ${(props) =>
        props.centerContent &&
        css`
        justify-content: center;
        align-items: center;
    `}
    ${(props) =>
        props.fullWidth &&
        css`
        max-width: 100% !important;
    `}

    @media (max-width: ${em('768')}) { 
        max-width: 85%;
        ${(props) =>
        props.responsive?.tablet &&
        css`
            height: ${p => props.responsive?.tablet?.height || ''};
            max-width: ${p => props.responsive?.tablet?.maxWidth || ''};
            width: ${p => props.responsive?.tablet?.width || ''};
            flex-direction: ${p => props.responsive?.tablet?.direction || ''};
            gap: ${p => props.responsive?.tablet?.gap || ''};
            align-self: ${p => props.responsive?.tablet?.alignSelf || ''};
            align-items: ${p => props.responsive?.mobile?.alignItems || ''};
            margin-top: ${p => props.responsive?.tablet?.marginTop || ''};
            margin-bottom: ${p => props.responsive?.tablet?.marginBottom || ''};
            display: ${p => props.responsive?.mobile?.display || ''};
            justify-content: ${p => props.responsive?.mobile?.justify || ''};
            flex-wrap: ${p => props.responsive?.mobile?.flexWrap || ''};
            flex-basis: ${p => props.responsive?.mobile?.flexBasic || ''};
        `}
    }
    
    @media (max-width: ${em('425')}) { 
        max-width: 90%;
        ${(props) =>
        props.responsive?.mobile &&
        css`
            height: ${p => props.responsive?.mobile?.height || ''};
            width: ${p => props.responsive?.mobile?.width || ''};
            max-width: ${p => props.responsive?.mobile?.maxWidth || ''};
            flex-direction: ${p => props.responsive?.mobile?.direction || ''};
            gap: ${p => props.responsive?.mobile?.gap || ''};
            align-self: ${p => props.responsive?.mobile?.alignSelf || ''};
            align-items: ${p => props.responsive?.mobile?.alignItems || ''};
            margin-top: ${p => props.responsive?.mobile?.marginTop || ''};
            margin-bottom: ${p => props.responsive?.mobile?.marginBottom || ''};
            display: ${p => props.responsive?.mobile?.display || ''};
            justify-content: ${p => props.responsive?.mobile?.justify || ''};
            flex-wrap: ${p => props.responsive?.mobile?.flexWrap || ''};
            flex-basis: ${p => props.responsive?.mobile?.flexBasic || ''};
        `}
    }
    
    // X-Large devices (large desktops, ${em('1024')} and up)
    @media (min-width: ${em('1200')}) { 
        max-width: ${em('910')};
    }
    
    // XX-Large devices (larger desktops, ${em('1440')} and up)
    @media (min-width: ${em('1440')}) { 
        max-width: ${em('1150')};
    }
`;

export const Group = styled(Container) <ViewProps>``

interface ButtonProps extends TextProps {
    radius?: string;
    size?: string;
    backgroundColor?: string;
}
export const Button = styled.button<ButtonProps>`
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    text-align: left;
    -webkit-text-decoration: none;
    text-decoration: none;
    min-height: 1rem;
    box-sizing: border-box;
    padding: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    width: auto;
    position: relative;
    line-height: 1;
    font-size: ${props => props.size || designSystem.fontSizes.lowM};
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    border: ${em(10)} solid transparent;
    border-radius: ${props => props.radius || em(15)};
    background-color: ${props => props.backgroundColor || '#228be6'};
    color: ${props => props.color || designSystem.colors.contentHigh};
    font-family: ${props => props.bold ? designSystem.fontFamily.bold : designSystem.fontFamily.medium};
    margin-top: ${props => props.marginTop || ''};
    margin-bottom: ${props => props.marginBottom || ''};
`

interface ImageProps {
    height?: string;
    width?: string;
    maxWidth?: string;
    margin?: string;
    marginTop?: string;
    marginBottom?: string;
    fit?: string;
    cursor?: string;
    responsive?: {
        mobile?: {
            height?: string;
            width?: string;
            maxWidth?: string;
            display?: string;
        }
        tablet?: {
            height?: string;
            width?: string;
            maxWidth?: string;
            display?: string;
        }
    }
}
export const Image = styled.img<ImageProps>`
    height: ${props => props.height || 'fit-content'};
    width: ${props => props.width || 'fit-content'};
    max-width: ${props => props.maxWidth || 'fit-content'};
    margin: ${props => props.margin || ''};
    margin-top: ${props => props.marginTop || ''};
    margin-bottom: ${props => props.marginBottom || ''};
    object-fit: ${props => props.margin || 'contain'};
    cursor: ${props => props.cursor || ''};

    @media (max-width: ${em('768')}) { 
        max-width: 85%;
        ${(props) =>
        props.responsive?.tablet &&
        css`
            height: ${p => props.responsive?.tablet?.height || ''};
            width: ${p => props.responsive?.tablet?.width || ''};
            display: ${p => props.responsive?.mobile?.display || ''};
            max-width: ${p => props.responsive?.mobile?.maxWidth || ''};
        `}
    }
    @media (max-width: ${em('425')}) { 
        max-width: 90%;
        ${(props) =>
        props.responsive?.mobile &&
        css`
            height: ${p => props.responsive?.mobile?.height || ''};
            width: ${p => props.responsive?.mobile?.width || ''};
            display: ${p => props.responsive?.mobile?.display || ''};
            max-width: ${p => props.responsive?.mobile?.maxWidth || ''};
        `}
    }
    
`

export const NavigationLink = styled(ReactLink) <TextProps>`
    color: ${props => props.color || designSystem.colors.contentHigh};
    text-decoration: none;
    font-size: ${props => props.size || designSystem.fontSizes.lowM};
    font-family: ${props => props.bold ? designSystem.fontFamily.bold : designSystem.fontFamily.medium};
`

export const Link = styled.a<TextProps>`
    color: ${props => props.color || designSystem.colors.contentHigh};
    text-decoration: none;
    font-size: ${props => props.size || designSystem.fontSizes.lowM};
    font-family: ${props => props.bold ? designSystem.fontFamily.bold : designSystem.fontFamily.medium};

    @media (max-width: ${em('425')}) { 
        ${(props) =>
        props.responsive?.mobile &&
        css`
            display: ${p => props.responsive?.mobile?.display || ''};
            font-size: ${p => props.responsive?.mobile?.size || ''};
        `}
    }

    @media (max-width: ${em('768')}) { 
        ${(props) =>
        props.responsive?.tablet &&
        css`
            display: ${p => props.responsive?.tablet?.display || ''};
            font-size: ${p => props.responsive?.tablet?.size || ''};
        `}
    }
`