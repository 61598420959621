import { useState } from 'react';
import { useLayoutContext } from "../layout/layout-provider";
import classNames from "classnames";
import HeaderStyle, { HeaderHeight, ImageHeaderHeight } from './header-style'
import {useNavigate} from 'react-router-dom';
import * as DS from '../../ds/style'

interface HeaderProps {
    links: { link: string; label: string; links: { link: string; label: string }[] }[];
    action?: string,
    className?: string
}

function Header({ links, action, className }: HeaderProps) {
    const navigate = useNavigate();

    const [active, setActive] = useState(0);
    const { backgroundColor, textColor, isLightLogo } = useLayoutContext();
    const mainItems = links.map((item, index) => (
        <DS.Link
            href={item.link}
            key={item.label}
            className={classNames('nav-row__link', {
                'nav-row__link--active': index === active
            })}
            onClick={() => {
                setActive(index);
            }}
            color={textColor}
            size={DS.fontSizes.highM}
            bold='true'
            responsive={{
                tablet:{
                    size:DS.fontSizes.highS
                }
            }}
        >
            {item.label}
        </DS.Link>
    ));

    const logo = isLightLogo ? './images/logo_light.svg' : './images/logo.svg'

    return (
        <DS.Container
            height={HeaderHeight}
            backgroundColor={backgroundColor}
            fullWidth
            padding={DS.spacing.x5}
        >
            <DS.Container
                className="header__container"
                center='true'
                direction='row'
                responsive={{
                    mobile:{
                        justify: 'center'
                    }
                }}
            >
                <DS.Group
                    className="header__image__container"
                    width='20%'
                    responsive={{
                        mobile:{
                            width:'100%',
                            alignItems:'center'
                        }
                    }}
                >
                    <DS.Image
                        className="header__image"
                        src={logo}
                        height={ImageHeaderHeight}
                        onClick={() => navigate('/')}
                        cursor='pointer'
                    />
                </DS.Group>
                <DS.Group
                    className="header__items__container"
                    justify='center'
                    width='60%'
                    direction='row'
                    gap={DS.spacing.x5}
                    responsive={{
                        mobile:{
                            display:'none'
                        }
                    }}
                >
                    {mainItems}
                </DS.Group>
            </DS.Container>
            <HeaderStyle />
        </DS.Container >
    )
}

export default Header