import * as DS from '../ds/style'
import { useLayoutContext } from "../shared/layout/layout-provider";
import { useEffect } from 'react'

function TermsOfUseView(): JSX.Element {
    const { setBackgroundColor, setTextColor, setLightLogo } = useLayoutContext();

    useEffect(() => {
        setBackgroundColor(DS.colors.backgroundBase)
        setTextColor(DS.colors.contentHigh)
        setLightLogo(false)
    }, []);

    return (
        <DS.Container direction='column' center marginTop={DS.spacing.x10} marginBottom={DS.spacing.x10} width='70%'>
            <DS.Text>
                Terms of Use
                <br /><br />
                By accessing or using the Wifi Pass application (the “App”), you agree to be bound by these Terms of Use (“Terms”). If you do not agree to these Terms, please do not use the App.
                <br /><br />
                Wifi Pass reserves the right to modify, update, or discontinue the App at any time without notice to you.
                <br /><br />
                User Conduct
                You agree that you will not use the App to:
                <br /><br />
                Collect or harvest personal information, including account names, from the App
                Use the App in any manner that could disable, overburden, damage, or impair the App or interfere with any other party's use of the App
                Use any manual or automated process to monitor or copy any of the material on the App for any unauthorized purpose
                Use any device, software, or routine that interferes with the proper working of the App
                Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful
                Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the App, the server on which the App is stored, or any server, computer, or database connected to the App
                Attack the App via a denial-of-service attack or a distributed denial-of-service attack
                Intellectual Property Rights
                The App and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by Wifi Pass, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
                <br /><br />
                These Terms permit you to use the App for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our App, except as follows:
                <br /><br />
                Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials
                You may store files that are automatically cached by your Web browser for display enhancement purposes
                You may print or download one copy of a reasonable number of pages of the App for your own personal, non-commercial use and not for further reproduction, publication, or distribution
                If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end-user license agreement for such applications.
                <br /><br />
                Trademarks
                The Wifi Pass name and logo, and all related names, logos, product and service names, designs, and slogans are trademarks of Wifi Pass or its affiliates or licensors. You must not use such marks without the prior written permission of Wifi Pass. All other names, logos, product and service names, designs, and slogans on the App are the trademarks of their respective owners.
                <br /><br />
                Disclaimer of Warranties
                The App is provided “as is” and “as available” basis, without any warranty of any kind, either express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, non-infringement, and any other warranty that may arise under any applicable law. Wifi Pass does not warrant that the App will be available uninterrupted or error-free, that defects will be corrected, or that the App is free of viruses or other harmful components.
                <br /><br />
                Limitation of Liability:
                Under no circumstances shall Wifi Pass, its affiliates, or their licensors be liable for any indirect, incidental, special, consequential or punitive damages, including, without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
                <br /><br />
                Disclaimer:
                Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.
                <br /><br />
                Wifi Pass, its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.
                <br /><br />
                Exclusions:
                Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.
                <br /><br />
                Governing Law:
                These Terms shall be governed and construed in accordance with the laws of EEUU, without regard to its conflict of law provisions.
                <br /><br />
                Changes:
                We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                <br /><br />    
                Contact Us:
                If you have any questions about these Terms, please contact us at help[at]wifi-pass[dot]app.
            </DS.Text>
        </DS.Container>
    )
}

export default TermsOfUseView;