import * as DS from '../../ds/style'
import { useTranslation } from 'react-i18next';
import FooterStyle from "./footer-style";

interface FooterProps {


}

function Footer({ }: FooterProps) {
    const { t } = useTranslation();
    const year = new Date().getFullYear()

    return (
        <DS.Container
            center='true'
            marginTop={DS.spacing.x20}
            marginBottom={DS.spacing.x5}
            gap={DS.spacing.x10}
        >
            <DS.Container
                center='true'
                className="footer__container"
                direction='row'
                responsive={{
                    mobile: {
                        direction: 'column',
                        gap: DS.spacing.x5
                    }
                }}
            >
                <DS.Container
                    center='true'
                    width='25%'
                    gap={DS.spacing.x4}
                    responsive={{
                        mobile: {
                            width: '100%',
                            alignItems: 'center'
                        }
                    }}
                >
                    <DS.Image
                        className='foter__image'
                        src='./images/footer_logo.svg'
                    />
                    <DS.Text
                        bold='true'
                        responsive={{
                            mobile: {
                                align: 'center'
                            }
                        }}
                    >{t('footer-description')}</DS.Text>
                </DS.Container>
                <DS.Container
                    className="footer__items__container"
                    center='true'
                    width='80%'
                    justify='end'
                    direction='row'
                    responsive={{
                        mobile: {
                            width: '100%',
                            justify: 'center',
                            flexWrap: 'wrap',
                        }
                    }}
                >
                    <DS.Container
                        width='25%'
                        gap={DS.spacing.x2}
                        responsive={{
                            mobile:{
                                flexBasic: '50%'
                            }
                        }}
                    >
                        <DS.Text bold>{t('footer-company-title')}</DS.Text>
                        <DS.NavigationLink color={DS.colors.contentMid} to=''>{t('footer-company-item-about-us-title')}</DS.NavigationLink>
                        <DS.NavigationLink color={DS.colors.contentMid} to=''>{t('footer-company-item-contact-title')}</DS.NavigationLink>
                        <DS.NavigationLink color={DS.colors.contentMid} to=''>{t('footer-company-item-blog-title')}</DS.NavigationLink>
                    </DS.Container>
                    <DS.Container 
                    width='25%' 
                    gap={DS.spacing.x2}
                    responsive={{
                        mobile:{
                            flexBasic: '50%'
                        }
                    }}
                    >
                        <DS.Text bold>{t('footer-legal-title')}</DS.Text>
                        <DS.NavigationLink color={DS.colors.contentMid} to='/privacy-policy'>{t('footer-legal-item-privacy-title')}</DS.NavigationLink>
                        <DS.NavigationLink color={DS.colors.contentMid} to='/cookies-policy'>{t('footer-legal-item-cookies-title')}</DS.NavigationLink>
                        <DS.NavigationLink color={DS.colors.contentMid} to='/terms-of-use'>{t('footer-legal-item-terms-title')}</DS.NavigationLink>
                    </DS.Container>
                    <DS.Container 
                    width='25%' 
                    gap={DS.spacing.x2}
                    responsive={{
                        mobile:{
                            flexBasic: '100%',
                            alignItems:'start',
                            maxWidth:'100%',
                            marginTop:DS.spacing.x5
                        }
                    }}
                    >
                        <DS.Text bold>{t('footer-social-title')}</DS.Text>
                        <DS.NavigationLink color={DS.colors.contentMid} to=''>{t('footer-social-item-instagram-title')}</DS.NavigationLink>
                        <DS.NavigationLink color={DS.colors.contentMid} to=''>{t('footer-social-item-twitter-title')}</DS.NavigationLink>
                    </DS.Container>
                </DS.Container>
            </DS.Container>
            <DS.Text align='center' color={DS.colors.contentLow}>{t('footer-copyright-title', { year: year })}</DS.Text>
            <FooterStyle />
        </DS.Container>
    )
}

export default Footer