import * as DS from '../ds/style'
import { useLayoutContext } from "../shared/layout/layout-provider";
import { useEffect } from 'react'

function PrivacyPolicyView(): JSX.Element {
    const { setBackgroundColor, setTextColor, setLightLogo } = useLayoutContext();

    useEffect(() => {
        setBackgroundColor(DS.colors.backgroundBase)
        setTextColor(DS.colors.contentHigh)
        setLightLogo(false)
    }, []);

    return (
        <DS.Container direction='column' center marginTop={DS.spacing.x10} marginBottom={DS.spacing.x10} width='70%'>
            <DS.Text>
                Privacy Policy
                <br />
                <br />
                Wifi Pass ("we", "us" or "our") is committed to protecting your privacy. This privacy policy explains how we collect, use and protect your personal information when you use our Wifi Pass application ("the application"). By using the application, you agree to the terms of this privacy policy.
                <br />
                <br />
                Information Collection:
                When you use our application, we may collect information about your device, including your IP address, operating system, browser type and other similar data. We may also collect information about your geographic location through your device's location. Additionally, we may collect information about open Wi-Fi networks available in your area.
                <br />
                <br />
                Use of Information:
                We use the information we collect to provide and improve the application, including to help you find and connect to nearby Wi-Fi networks. We may also use the information for research and analysis purposes, as well as to send updates and notifications about the application.
                <br />
                <br />
                Disclosure of Information:
                We do not share your personal information with third parties, except as necessary to provide the application or as required by law. We may share anonymous or aggregated information with third parties for research and analysis purposes.
                <br />
                <br />
                Your Rights:
                You have the right to access, rectify or delete your personal data. You can request that we delete your data by contacting us via email and providing the necessary information to verify your identity.
                <br />
                <br />
                User Responsibility:
                Users are responsible for obtaining necessary authorization to share information about open Wi-Fi networks. We are not responsible for any misuse of the information obtained through the application.
                <br />
                <br />
                Modifications to Privacy Policy:
                We reserve the right to modify this privacy policy at any time. Modifications will be effective upon posting in the application.
                <br />
                <br />
                Contact:
                If you have any questions about this privacy policy, please contact us through the email help[at]wifi-pass[dot]app
                <br />
                <br />
                Last Updated: 01/03/2023
            </DS.Text>
        </DS.Container>
    )
}

export default PrivacyPolicyView;