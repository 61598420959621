import { em } from '@mantine/core';

type Colors = {
    [key: string]: string;
}

type FontSizes = {
    [key: string]: string;
}

type Spacing = {
    [key: string]: string;
}

type FontFamily = {
    [key: string]: string;
}

interface DesignSystem {
  colors: Colors;
  fontSizes: FontSizes;
  fontFamily: FontFamily;
  spacing: Spacing;
}

const designSystem : DesignSystem = {
    colors: {
        contentHigh: '#1A1A1A',
        contentMid: '#7B7B7B',
        contentLow: '#BABABA',
        contentInverse: '#FFFFFF',
        primaryHigh: '#007dda',
        primaryMid: '#AAD5F3',
        primaryLow: '#D0D3D6',
        backgroundBase: '#FFFFFF',
        backgroundAlt: '#F1F1F1',
    },
    fontSizes: {
        lowS: em(10),
        lowM: em(16),
        lowL: em(22),
        lowXL: em(28),
        highS: em(16),
        highM: em(22),
        highL: em(28),
        highXL: em(36),
        highXXL: em(56),
    },
    fontFamily: {
        medium: 'Satoshi Medium',
        bold: 'Satoshi Bold'
    },
    spacing: {
        x1: em(6),
        x2: em(12),
        x3: em(18),
        x4: em(24),
        x5: em(30),
        x6: em(36),
        x7: em(42),
        x8: em(48),
        x9: em(54),
        x10: em(60),
        x11: em(66),
        x12: em(72),
        x13: em(78),
        x14: em(84),
        x15: em(90),
        x16: em(96),
        x17: em(102),
        x18: em(108),
        x19: em(114),
        x20: em(120),
    }
};

export default designSystem;
