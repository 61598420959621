import { createGlobalStyle } from 'styled-components';
import designSystem from "../ds/theme"
import { em } from '@mantine/core';

export const HOME_HEADER_PHONE_BACKGROUND_HEIGHT = em(250)
export const HOME_HEADER_PHONE_IMAGE_HEIGHT = em(550)
export const HOME_HEADER_PHONE_IMAGE_TABLET_HEIGHT = em(350)
export const HOME_HEADER_PHONE_IMAGE_MOBILE_HEIGHT = em(300)
export const HOME_FEATURE_IMAGE_HEIGHT = em(100)
export const HOME_DOWNLOAD_IMAGE_HEIGHT = em(200)

export default createGlobalStyle`
    .home__header__phone__container{
        position: relative;
        margin-left: -15%;
        margin-bottom: -${designSystem.spacing.x6};
    }
    .home__header__phone__image{
        position: absolute;
        transform: translate(70%, 10%);
    }

    @media (max-width: ${em('768')}) { 
        .home__header__phone__container{
            margin: 0 auto;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: -${designSystem.spacing.x5};
        }
        .home__header__phone__image{
            transform: translate(7%, 20%);
        }
    }

    @media (max-width: ${em('425')}) { 
        .home__header__phone__container{
            margin: 0 auto;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: -${designSystem.spacing.x10};
        }
        .home__header__phone__image{
            position: relative;
            transform: translate(7%, 25%);
        }
    }
    
`