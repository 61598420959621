import { useEffect } from 'react'
import * as DS from '../ds/style'
import { useLayoutContext } from "../shared/layout/layout-provider";
import HomeStyle, {
    HOME_HEADER_PHONE_BACKGROUND_HEIGHT,
    HOME_HEADER_PHONE_IMAGE_HEIGHT,
    HOME_HEADER_PHONE_IMAGE_TABLET_HEIGHT,
    HOME_HEADER_PHONE_IMAGE_MOBILE_HEIGHT,
    HOME_FEATURE_IMAGE_HEIGHT,
    HOME_DOWNLOAD_IMAGE_HEIGHT
} from "./home-style";
import { useTranslation } from 'react-i18next';

function HomeView(): JSX.Element {
    const { t } = useTranslation();
    const { setBackgroundColor, setTextColor, setLightLogo } = useLayoutContext();
    const homeMainColor = DS.colors.primaryHigh
    const headerBackgroundColor = homeMainColor
    const headerTextColor = DS.colors.contentInverse

    useEffect(() => {
        setBackgroundColor(headerBackgroundColor)
        setTextColor(headerTextColor)
        setLightLogo(true)
    }, []);

    const downloadElement = document.getElementById('download-app');

    return (
        <DS.Container fullWidth>
            <DS.Container className="home__header__container" backgroundColor={homeMainColor} fullWidth>
                <DS.Container center centerContent>
                    <DS.Text
                        size={DS.fontSizes.highXXL}
                        className="home__header__title"
                        color={DS.colors.contentInverse}
                        width="70%"
                        bold='true'
                        align='center'
                        marginTop={DS.spacing.x5}
                        marginBottom={DS.spacing.x3}
                        responsive={{
                            tablet: {
                                size: DS.fontSizes.highXL
                            },
                            mobile: {
                                size: DS.fontSizes.highL,
                                width: '100%'
                            }
                        }}
                    >
                        {t('home-header-title')}
                    </DS.Text>
                    <DS.Button
                        onClick={() => downloadElement?.scrollIntoView({ behavior: 'smooth' })}
                        className="home__header__button"
                        bold
                        backgroundColor={DS.colors.contentInverse}
                    >
                        {t('home-header-action')}
                    </DS.Button>
                    <DS.Group className='home__header__phone__container'
                        alignSelf='flex-start'
                        marginTop={DS.spacing.x10}
                    >
                        <DS.Image
                            className='home__header__phone__background'
                            src="./images/home_header_phone_back.svg"
                            height={HOME_HEADER_PHONE_BACKGROUND_HEIGHT}
                            responsive={{
                                mobile: {
                                    display: 'none'
                                }
                            }}
                        />
                        <DS.Image
                            className='home__header__phone__image'
                            src="./images/home_header_phone.png"
                            height={HOME_HEADER_PHONE_IMAGE_HEIGHT}
                            responsive={{
                                tablet: {
                                    height: HOME_HEADER_PHONE_IMAGE_TABLET_HEIGHT
                                },
                                mobile: {
                                    height: HOME_HEADER_PHONE_IMAGE_MOBILE_HEIGHT,
                                }
                            }}
                        />
                    </DS.Group>
                </DS.Container>
            </DS.Container>
            <DS.Container
                className='home__body__statistics__container'
                marginTop={DS.spacing.x10}
                center='true'
                marginBottom={DS.spacing.x10}
            >
                <DS.Container
                    justify='center'
                    gap={DS.spacing.x10}
                    width='60%'
                    alignSelf='end'
                    alignItems='center'
                    direction='row'
                    responsive={{
                        tablet: {
                            alignSelf: 'center'
                        },
                        mobile: {
                            alignSelf: 'center',
                            direction: 'column',
                            marginTop: DS.spacing.x20
                        }
                    }}
                >
                    <DS.Group className='home__body__statistics__item' gap={DS.spacing.x2}>
                        <DS.Text align='center' size={DS.fontSizes.highL} bold>
                            {t('home-statistics-total-hostpot-title')}
                        </DS.Text>
                        <DS.Text align='center' bold>
                            {t('home-statistics-total-hostpot-description')}
                        </DS.Text>
                    </DS.Group>
                    <DS.Group className='home__body__statistics__item' gap={DS.spacing.x2}>
                        <DS.Text align='center' size={DS.fontSizes.highL} bold>
                            {t('home-statistics-total-countries-title')}
                        </DS.Text>
                        <DS.Text align='center' bold>
                            {t('home-statistics-total-countries-description')}
                        </DS.Text>
                    </DS.Group>
                    <DS.Group className='home__body__statistics__item' gap={DS.spacing.x2}>
                        <DS.Text align='center' size={DS.fontSizes.highL} bold>
                            {t('home-statistics-total-cities-title')}
                        </DS.Text>
                        <DS.Text align='center' bold>
                            {t('home-statistics-total-cities-description')}
                        </DS.Text>
                    </DS.Group>
                </DS.Container>
                <DS.Container
                    justify='center'
                    width='60%'
                    alignSelf='end'
                    direction='row'
                    marginTop={DS.spacing.x10}
                    responsive={{
                        tablet: {
                            alignSelf: 'center'
                        },
                        mobile: {
                            alignSelf: 'center'
                        }
                    }}
                >
                    <DS.Text className='home__body__statistics__description' align='center'>
                        {t('home-statistics-description')}
                    </DS.Text>
                </DS.Container>
            </DS.Container>
            <DS.Container
                className='home__feature__container'
                center='true'
                marginTop={DS.spacing.x20}
                id="features"
                responsive={{
                    tablet: {
                        marginTop: DS.spacing.x5
                    },
                    mobile: {
                        marginTop: DS.spacing.x5
                    }
                }}
            >
                <DS.Text
                    bold='true'
                    size={DS.fontSizes.highL}
                    responsive={{
                        mobile: {
                            align: 'center'
                        }
                    }}
                >
                    {t('home-features-title.normal')} <DS.Color color={DS.colors.primaryHigh}>{t('home-features-title.colored')}</DS.Color>
                </DS.Text>
                <DS.Container
                    justify='space-around'
                    marginTop={DS.spacing.x15}
                    direction='row'
                    center='true'
                    alignItems='center'
                    responsive={{
                        mobile: {
                            direction: 'column',
                            gap: DS.spacing.x10
                        }
                    }}
                >
                    <DS.Group className='home__feature__item' gap={DS.spacing.x2} alignItems='center'>
                        <DS.Image
                            className='home__feature__image'
                            src='./images/home_feature_map.svg'
                            height={HOME_FEATURE_IMAGE_HEIGHT}
                        />
                        <DS.Text align='center' bold width='70%'>
                            {t('home-features-map')}
                        </DS.Text>
                    </DS.Group>
                    <DS.Group className='home__feature__item' gap={DS.spacing.x2} alignItems='center'>
                        <DS.Image
                            className='home__feature__image'
                            src='./images/home_feature_share.svg'
                            height={HOME_FEATURE_IMAGE_HEIGHT}
                        />
                        <DS.Text align='center' bold width='70%'>
                            {t('home-features-share')}                        </DS.Text>
                    </DS.Group>
                    <DS.Group className='home__feature__item' gap={DS.spacing.x2} alignItems='center'>
                        <DS.Image
                            className='home__feature__image'
                            src='./images/home_feature_leaderboard.svg'
                            height={HOME_FEATURE_IMAGE_HEIGHT}
                        />
                        <DS.Text align='center' bold width='70%'>
                            {t('home-features-leaderboard')}                         </DS.Text>
                    </DS.Group>
                </DS.Container>
            </DS.Container>
            <DS.Container
                className='home__app__container'
                center
                marginTop={DS.spacing.x15}
                gap={DS.spacing.x5}
                id="app"
            >
                <DS.Text size={DS.fontSizes.highL} bold>
                    {t('home-app-title.normal')} <DS.Color color={DS.colors.primaryHigh}>{t('home-app-title.colored')}</DS.Color>
                </DS.Text>
                <DS.Text align='justify'>
                    {t('home-app-description')}
                </DS.Text>
                <DS.Image
                    className='home__app__image'
                    src='./images/home_screenshots.png'
                    width='65%'
                    marginTop={DS.spacing.x10}
                    margin='0 auto'
                />
            </DS.Container>
            <DS.Container
                className='home__download__container'
                direction='row'
                id='download-app'
                center='true'
                backgroundColor={homeMainColor}
                padding={DS.spacing.x8}
                marginTop={DS.spacing.x10}
                responsive={{
                    mobile: {
                        direction: 'column',
                        gap: DS.spacing.x10
                    }
                }}
            >
                <DS.Container
                    center='true'
                    width='50%'
                    alignSelf='start'
                    gap={DS.spacing.x4}
                    responsive={{
                        mobile: {
                            width: '100%'
                        }
                    }}
                >
                    <DS.Text
                        size={DS.fontSizes.highXL}
                        bold='true'
                        color={DS.colors.contentInverse}
                        responsive={{
                            mobile: {
                                align: 'center'
                            }
                        }}
                    >
                        {t('home-download-title')}
                    </DS.Text>
                    <DS.Text
                        bold='true'
                        color={DS.colors.contentInverse}
                        responsive={{
                            mobile: {
                                display: 'none'
                            }
                        }}
                    >
                        {t('home-download-description')}
                    </DS.Text>
                </DS.Container>
                <DS.Container
                    center
                    width='50%'
                    alignSelf='start'
                    alignItems='center'
                    gap={DS.spacing.x4}
                    responsive={{
                        mobile:{
                            width:'100%'
                        }
                    }}
                >
                    <DS.Image
                        className='home__download__image'
                        src='./images/home_download_play_store.png'
                        width={HOME_DOWNLOAD_IMAGE_HEIGHT}
                        maxWidth='50%'
                        cursor='pointer'
                    />
                    <DS.Image
                        className='home__download__image'
                        src='./images/home_download_app_store.png'
                        width={HOME_DOWNLOAD_IMAGE_HEIGHT}
                        maxWidth='50%'
                        cursor='pointer'
                    />
                </DS.Container>
            </DS.Container>
            <HomeStyle />
        </DS.Container >
    )
}

export default HomeView
