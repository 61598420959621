import React from "react";
import { Route, Routes } from "react-router-dom";
import TermsOfUseView from "./terms-of-use-view";

const TermsOfUseRoutes = React.memo(() => {
    return (
        <Routes>
            <Route path="/terms-of-use" element={<TermsOfUseView />} />
        </Routes>
    );
});

export default TermsOfUseRoutes;
