import { createContext, useContext, useState, ReactNode } from 'react';
import * as DS from '../../../ds/style'

export const defaultRpcConfig = {
    backgroundColor: 'white',
    setBackgroundColor: (color: string) => { },
    textColor: 'white',
    setTextColor: (color: string) => { },
    isLightLogo: false,
    setLightLogo: (isLightLogo: boolean) => { },
};

const LayoutContext = createContext(defaultRpcConfig);

const LayoutProvider = ({ children }: { children: ReactNode }) => {
    const [backgroundColor, setBackgroundColor] = useState(DS.colors.backgroundBase);
    const [textColor, setTextColor] = useState(DS.colors.contentHigh);
    const [isLightLogo, setLightLogo] = useState(false);

    return (
        <LayoutContext.Provider value={{
            backgroundColor: backgroundColor,
            setBackgroundColor: setBackgroundColor,
            textColor: textColor,
            setTextColor: setTextColor,
            isLightLogo: isLightLogo,
            setLightLogo: setLightLogo
        }}>
            {children}
        </LayoutContext.Provider>
    );
};

export const useLayoutContext = () =>
    useContext(LayoutContext);

export default LayoutProvider;