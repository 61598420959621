import React from "react";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicyView from "./privacy-policy-view";

const PrivacyPolicyRoutes = React.memo(() => {
    return (
        <Routes>
            <Route path="/privacy-policy" element={<PrivacyPolicyView />} />
        </Routes>
    );
});

export default PrivacyPolicyRoutes;
