import React, { forwardRef, ReactNode } from "react";
import LayoutProvider from "./layout-provider";
import Head from "../head/head";
import Header from '../../shared/header/header';
import Footer from '../../shared/footer/footer';
import classNames from "classnames";
import { useTranslation } from 'react-i18next';

type LayoutProps = {
    children: ReactNode;
    className?: string;
    style?: React.CSSProperties;
};

const Layout = forwardRef<HTMLDivElement, LayoutProps>(
    function ({ children, className, style }: LayoutProps, ref) {
        const { t } = useTranslation();
        const links = [
            { link: '/', label: t('header_item_home'), links: [] },
            { link: '#features', label: t('header_item_features'), links: [] },
            { link: '#app', label: t('header_item_about'), links: [] },
        ];
        return (
            <LayoutProvider>
                <Head
                    meta={[
                        {
                            name: "viewport",
                            content: "width=device-width, initial-scale=1.0",
                        },
                    ]}
                    title="Wifi-Pass"
                />
                <div
                    className={classNames("layout", className)}
                    style={style}
                    ref={ref}
                >
                    <Header links={links} className="box--container" />
                    {children}
                </div>
                <Footer />
            </LayoutProvider>
        )
    }
);


export default Layout