import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import Navigation from './navigation';
import './index.css';
import Layout from './app/shared/layout/layout';
import ScrollToTop from '../src/app/utils/ScrollToTop';
import './i18n';
import { initializeApp } from "firebase/app";
import { getAnalytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDrT63rrk1-Aa8hADzXGKGdi3Jhb-2ttJo",
    authDomain: "wifi-pass-18b06.firebaseapp.com",
    projectId: "wifi-pass-18b06",
    storageBucket: "wifi-pass-18b06.appspot.com",
    messagingSenderId: "1068699933022",
    appId: "1:1068699933022:web:c3b0db134bc023672c97cf",
    measurementId: "G-5VS1DZMHLM"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop />
            <Suspense fallback="">
                <Layout>
                    <Navigation />
                </Layout>
            </Suspense>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);