import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeView from "./home-view";

const HomeRoutes = React.memo(() => {
    return (
        <Routes>
            <Route path="/" element={<HomeView />} />
        </Routes>
    );
});

export default HomeRoutes;
