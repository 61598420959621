import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

type HeadProps = {
    children?: PropTypes.ReactNodeLike;
    [key: string]: any;
}

const Head = ({ children, ...rest }: HeadProps) => (
    <Helmet {...rest} defer={false}>
        {children}
    </Helmet>
);

export default Head;